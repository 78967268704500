var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"role":"main"}},[_c('meta-title',{attrs:{"title":_vm.metaTitle}}),_c('section',{staticClass:"section section-route"}),_c('section',{staticClass:"section-first-page-hero"}),_vm._l((_vm.indexData.blocks),function(item,index){return _c('section',{key:index,staticClass:"section section-carousel"},[_c('div',{staticClass:"section-header m-container"},[_c('h2',[_vm._v(" "+_vm._s(item.sections[0].title)+" ")]),(_vm.indexSectionLoading === false)?_c('router-link',{staticClass:"more",attrs:{"to":{
          name: 'MovieList',
          params: {
            section: item.sections[0].id,
            collection: _vm.indexSectionData.find(
              function (it) { return it.id === item.sections[0].id; }
            ).collections[0].id
          }
        }}},[_vm._v(_vm._s(_vm.$t("section-menu.see all")))]):_vm._e()],1),_c('div',{staticClass:"carousel-wrapper"},[_c('div',{staticClass:"carousel-container"},[_vm._m(0,true),_vm._l((item.sections[0].items),function(itm,idx){return _c('div',{key:idx,staticClass:"carousel-item",class:{ 'music-item': itm.contentType === 'Album' }},[(itm.isPaid)?_c('i'):_vm._e(),_c('router-link',{attrs:{"to":{ path: _vm.generateRouteUrl(itm) }}},[_c('figure',{style:({
                'background-image':
                  'url(' + _vm.replaceCoverImage(itm.cover) + ')'
              })}),(parseInt(itm.year))?_c('p',[_vm._v(" "+_vm._s(itm.title)+" • "),_c('span',[_vm._v(_vm._s(itm.year))])]):_c('p',[_vm._v(" "+_vm._s(itm.title)+" ")])])],1)}),_vm._m(1,true)],2)])])})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"carousel-offset"},[_c('div')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"carousel-offset"},[_c('div')])}]

export { render, staticRenderFns }