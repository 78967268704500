<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-route">
      <!--      <RouteBar></RouteBar>-->
    </section>

    <section class="section-first-page-hero"></section>

    <section
      v-for="(item, index) in indexData.blocks"
      v-bind:key="index"
      class="section section-carousel"
    >
      <div class="section-header m-container">
        <h2>
          {{ item.sections[0].title }}
        </h2>
        <router-link
          v-if="indexSectionLoading === false"
          class="more"
          :to="{
            name: 'MovieList',
            params: {
              section: item.sections[0].id,
              collection: indexSectionData.find(
                it => it.id === item.sections[0].id
              ).collections[0].id
            }
          }"
          >{{ $t("section-menu.see all") }}</router-link
        >
      </div>

      <div class="carousel-wrapper">
        <div class="carousel-container">
          <div class="carousel-offset">
            <div />
          </div>
          <div
            class="carousel-item"
            :class="{ 'music-item': itm.contentType === 'Album' }"
            v-for="(itm, idx) in item.sections[0].items"
            :key="idx"
          >
            <i v-if="itm.isPaid"></i>
            <router-link :to="{ path: generateRouteUrl(itm) }">
              <figure
                v-bind:style="{
                  'background-image':
                    'url(' + replaceCoverImage(itm.cover) + ')'
                }"
              ></figure>
              <p v-if="parseInt(itm.year)">
                {{ itm.title }} • <span>{{ itm.year }}</span>
              </p>
              <p v-else>
                {{ itm.title }}
              </p>
            </router-link>
          </div>
          <div class="carousel-offset">
            <div />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import cover from "@/mixins/cover";

export default {
  name: "Index",
  title() {
    return this.$t("pages.main");
  },
  mixins: [cover],
  data: () => {
    return {
      video: null
    };
  },
  computed: {
    ...mapState("home", {
      indexData: state => state.index,
      indexLoading: state => state.indexLoading
    }),
    ...mapState("settings", {
      enabledDrm: state => state.enabledDrm
    }),
    ...mapState({
      locale: state => state.locale
    }),
    ...mapState("section", {
      indexSectionData: state => state.index,
      indexSectionLoading: state => state.indexLoading
    }),
    backgroundHeaderImage() {
      return {
        backgroundImage: `url(${require("@/assets/" +
          this.headerItems[this.step].image)})`
      };
    }
  },
  watch: {
    indexLoading: {
      handler() {
        if (this.indexLoading === "empty") {
          this.actionIndex();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("home", {
      actionIndex: "index"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    })
  }
};
</script>

<style scoped></style>
